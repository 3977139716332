import React from 'react';
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"

function App() {
  return (
    <SwaggerUI url="https://invotra.github.io/api/api.swagger.yaml" />
  );
}

export default App;
